<template>
  <div id="page-user-view">
    <div class="flex items-center justify-between mb-4">
      <h2>{{$t('Payments')}}</h2>
      <vs-button class="ml-auto mr-5 self-center" color="success" @click="showQuickbooks">Sync Quickbooks</vs-button> 
      <span class="font-medium text-primary cursor-pointer">
        <vs-button
          @click="newPayment('Add')"
          color="primary"
          icon-pack="feather"
          icon="icon-plus"
          size="large"
        >
        </vs-button>
      </span>
    </div>
    <shipblu-table
      :sst="true"
      multiple
      v-model="selected"
      orders
      pagination
      :max-items="maximumItems"
      :data="payments"
      @search="handleSearch"
      @keyup.enter.native="handleSearch"
      search
      :tableLoader="tableLoader"
    >
      <template slot="thead">
        <shipblu-th>{{$t('Date')}}</shipblu-th>
        <shipblu-th>{{$t('Amount')}}</shipblu-th>
        <shipblu-th>{{$t('Description')}}</shipblu-th>
        <shipblu-th>{{$t('Reference Number')}}</shipblu-th>
        <shipblu-th>{{$t('Deposit Slip')}}</shipblu-th>
        <shipblu-th>{{$t('Statement')}}</shipblu-th>
        <shipblu-th>{{$t('Account')}}</shipblu-th>
        <shipblu-th>{{$t('Paid To')}}</shipblu-th>
        <shipblu-th>{{$t('Status')}}</shipblu-th>
        <shipblu-th class="flex justify-center">
          <feather-icon icon="ChevronDownIcon" svgClasses="h-5 w-5"/>
        </shipblu-th>
      </template>

      <template slot-scope="{ data }">
        <shipblu-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data" :color="`${getStyle(data[indextr])}`">
          <shipblu-td class="sm:hidden block col-span-2 p-0 order-3">
            <!-- line -->
            <p style="background-color: #DCDCDC;height: 1px;"></p>
          </shipblu-td>

          <shipblu-td class="order-4" :data="data[indextr].date">
            <p class="sm:hidden block text-secondary text-sm mb-2">{{$t('Date')}}</p>
            <p class="sm:text-base text-sm">
              {{ data[indextr].date }}
            </p>
          </shipblu-td>

          <shipblu-td :data="data[indextr].amount" class="text-left sm:order-3 order-16 sm:pointer-events-none">
            <div class="flex sm:static absolute top-3 gap-2 items-start justify-start transform-50" v-on:click.stop>
              <p class="sm:text-base text-lg flex gap-1 items-start justify-center">
                {{ data[indextr].amount ? data[indextr].amount.toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2}) : '' }}
              </p>
            </div>
          </shipblu-td>

          <shipblu-td class="order-5" :data="data[indextr].description">
            <p class="sm:hidden block text-secondary text-sm mb-2">{{$t('Description')}}</p>
            <p class="sm:text-base text-sm">
              {{ data[indextr].description }}
            </p>
          </shipblu-td>

          <shipblu-td class="order-6" :data="data[indextr].reference_number">
            <p class="sm:hidden block text-secondary text-sm mb-2">{{$t('Reference Number')}}</p>
            <p class="sm:text-base text-sm">
              {{ data[indextr].reference_number }}
            </p>
          </shipblu-td>

          <shipblu-td class="order-7" :data="data[indextr].link_to_deposit_slip">
            <p class="sm:hidden block text-secondary text-sm mb-2">{{$t('Deposit Slip')}}</p>
            <p class="sm:text-base text-sm">
              <a @click="downloadPayment(data[indextr])" target="_blank">{{$t('Download')}}</a>
            </p>
          </shipblu-td>

          <shipblu-td class="order-8" :data="data[indextr].statement">
            <p class="sm:hidden block text-secondary text-sm mb-2">{{$t('Statement')}}</p>
            <p class="sm:text-base text-sm">
              {{ data[indextr].statement ? data[indextr].statement.number : ''}}
            </p>
          </shipblu-td>

          <shipblu-td class="order-9" :data="data[indextr].account">
            <p class="sm:hidden block text-secondary text-sm mb-2">{{$t('Account')}}</p>
            <p class="sm:text-base text-sm">
              {{ data[indextr].account ? data[indextr].account.name : '' }}
            </p>
          </shipblu-td>

          <shipblu-td class="order-10" :data="data[indextr].paid_to">
            <p class="sm:hidden block text-secondary text-sm mb-2">{{$t('Paid To')}}</p>
            <p class="sm:text-base text-sm">
            {{ data[indextr].paid_to ? data[indextr].paid_to.name : '' }}
            </p>
          </shipblu-td>

          <shipblu-td class="order-12" :data="data[indextr].status" style="white-space: nowrap;">
            <p class="sm:hidden block text-secondary text-sm mb-2">{{$t('Status')}}</p>
            <div class="flex" style="width: fit-content;">
              <span class="capitalize border border-solid rounded-4xl px-5 py-1.5 leading-none sm:text-sm text-xs text-white" :style="`background-color: ${getStyle(data[indextr])}`">{{ $t(getStatusLabel(data[indextr])) }}</span>
            </div>
          </shipblu-td>

          <shipblu-td class="order-2" style="vertical-align: middle;">
            <div class="md:text-center text-right" @click="removeAttribute($event)">
              <vs-dropdown
                vs-trigger-click
                @click="selected = []"
                class="dd-actions cursor-pointer"
              >
                <span class="material-symbols-outlined text-2xl">more_horiz</span> 
                <vs-dropdown-menu style="width: 190px">
                  <vs-dropdown-item @click="newPayment('Edit', data[indextr])">
                    <span class="flex items-center">
                      <feather-icon
                        icon="EditIcon"
                        svgClasses="h-4 w-4"
                        class="mr-2"
                      />
                      <span>{{$t('Edit')}}</span>
                    </span>
                  </vs-dropdown-item>
                </vs-dropdown-menu>
              </vs-dropdown>
            </div>
          </shipblu-td>
        </shipblu-tr>
      </template>
    </shipblu-table>
    <shipblu-pagination :maximumItems="maximumItems" :noOptions="true" :totalRows="totalRows" :currentPage="currentPage" @currentPage="currentPage = $event" />
    <add-payment :type="type" :accounts="accounts" :paymentModal="paymentModal" :status="status" :statements="statements"
    @paymentModal="paymentModal = $event" :payment="payment" @loadPayments="loadPayments"></add-payment>
  </div>
</template>
<script>
import AddPayment from './AddPayment.vue'
import {sendRequest} from '../../http/axios/requestHelper.js'
import common  from '@/assets/utils/common'
import ShipbluTable from '../../layouts/components/NewShipBluTable.vue'
import ShipbluTr from '../../layouts/components/ShipBluTr.vue'
import ShipbluTh from '../../layouts/components/ShipBluTh.vue'
import ShipbluTd from '../../layouts/components/ShipBluTd.vue'
import ShipbluPagination from '../../layouts/components/ShipbluPagination.vue'

export default {
  components: { 
    AddPayment,
    ShipbluTable,
    ShipbluTr,
    ShipbluTh,
    ShipbluTd,
    ShipbluPagination
  },
  data () {
    return {
      maximumItems: process.env.VUE_APP_MAXIMUM_ITEMS_TABLE,
      totalRows: 0,
      offset: 0,
      currentPage: (this.$route.query.page &&  Number(this.$route.query.page) > 0) ? Number(this.$route.query.page) : 1,
      paymentModal: false,
      type: '',
      selected: [],
      payments: [
        {
          account: {
            id: ''
          },
          statement: {
            id: ''
          }
        }
      ],
      statements: [],
      status: [
        'requested',
        'paid',
        'cancelled'
      ],
      payment: {},
      searchInProgress: false,
      searchedValue: ' ',
      searchVal: '',
      tableLoader: false,
      accounts: []
    }
  },
  watch: {
    currentPage () {
      this.offset = (this.currentPage - 1) * this.maximumItems
      this.$router.push({
        query: {
          page: this.currentPage
        }
      }).catch(() => {})
      this.loadPayments()
    }
  },
  methods: {
    getStyle (data) {
      return common.getOrderStatusColor(data.status)
    },
    getOrderStatusColor (order) {
      return common.getOrderStatusColor(this.getStatusLabel(order))
    },
    getStatusLabel (order) {
      return common.getStatusLabel(order, this.$store.state.AppActiveUser.userRole)
    },
    handleSearch (search) {
      this.offset = 0
      this.currentPage = 1
      this.searchVal = search.target ? search.target.value : search
      return common.manageSearch(search, this.loadPaymentsSearch)
    },
    loadPaymentsSearch () {
      if (this.searchInProgress || this.searchedValue === this.searchVal) {
        return
      }
      this.loadPayments()
    },
    loadPayments () {
      this.searchInProgress = true
      this.tableLoader = true
      const query = `?offset=${this.offset}&search=${this.searchVal}&limit=${this.maximumItems}`
      sendRequest(true, false, this, `api/v1/accounting/payments/${query}`, 'get', null, true, 
        (response) => {
          this.payments = response.data.results
          this.tableLoader = false
          this.totalRows = response.data.count
          this.searchedValue = this.searchVal
        }
      )
      this.searchInProgress = false
    },
    loadPaymentAccounts () {
      sendRequest(false, false, this, 'api/v1/accounting/payment-accounts/', 'get', null, true, 
        (response) => {
          this.accounts = response.data.results
        }
      )
      this.searchInProgress = false
    },
    loadStatements () {
      sendRequest(false, false, this, 'api/v1/billing/statements/?limit=100&net_cod_due=0', 'get', null, true, 
        (response) => {
          this.statements = response.data.results
        }
      )
    },
    newPayment (type, data) {
      this.type = type
      if (type === 'Add') {
        this.payment = {}
      } else {
        this.payment = data
      }
      this.paymentModal = true
    },
    downloadPayment (data) {
      sendRequest(false, false, this, `api/v1/accounting/payments/${data.id}/pdf/`, 'get', null, true, 
        (response) => {
          this.file_URl = response.data.file_url
          window.open(this.file_URl, '_blank')
        }
      )
    },
    showQuickbooks () {
      sendRequest(false, false, this, 'api/v1/accounting/quickbooks-sync/payments/', 'post', {}, true, 
        (respone) => {
          this.$vs.notify({
            color:'warning',
            title: 'Warning',
            text: respone.data,
            position: 'top-center'
          })
        }
      )
    }
  },
  created () {
    this.offset = (this.currentPage - 1) * this.maximumItems
    this.loadPayments()
    this.loadStatements()
    this.loadPaymentAccounts()
  }
}
</script>
<style lang="scss">
@import "@/assets/scss/vuexy/apps/email.scss";
.con-pagination-table{
  display: none;
}
</style>